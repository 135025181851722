<template>
  <div>
    <header>
      <div class="title-block">
        <div class="logo">
          <a>个人信息</a>
          <br>
          <!-- 使用v-if和v-else根据isLoggedIn的值来显示不同的内容 -->
          <a class="logo-user" v-if="user != null" href="#"><router-link to="/personalInfo">欢迎您  “{{ user.nickName }}”</router-link></a>
          <a class="logo-user" v-else href="#"><router-link to="/login">待登录...</router-link></a>
        </div>
      </div>
      <nav>
        <ul id="starlist">
          <li>
            <router-link to="/">网站首页</router-link>
          </li>
          <li>
            <router-link to="/about">关于我</router-link>
          </li>
          <li>
            <router-link to="/listBlog">博客日记</router-link>
          </li>
          <li>
            <router-link to="/photoAlbum">相册</router-link>
          </li>
          <li>
            <router-link to="/commentArea">评论区</router-link>
          </li>
          <li>
            <router-link to="/time">时间轴</router-link>
          </li>
        </ul>
      </nav>
    </header>
    <div class="box">
      <div class="newsview">
        <div class="info">
          <div class="profile-photo">
            <h1>个人信息</h1>
          </div>
          <div class="user-icon">
            <img :src="user.icon" style="width: 100px;height: 100px" alt="加载失败...">
          </div>
          <div class="user-base-info">
            <br/>
            <p v-if="updateNickName"><strong>昵称：</strong><input v-model="user.nickName" style="width: 80px"><a @click="updateInfo">&nbsp;&nbsp;&nbsp;&nbsp;保存</a></p>
            <p v-else-if="!updateNickName"><strong>昵称：</strong>{{ this.user.nickName }}<a @click="nickNameCheck">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;修改</a></p>
            <br/>
            <p v-if="user.rule == 0"><strong>类型：</strong>超级管理员</p>
            <p v-if="user.rule == 1"><strong>类型：</strong>管理</p>
            <p v-if="user.rule == 2"><strong>类型：</strong>高级用户</p>
            <p v-if="user.rule == 3"><strong>类型：</strong>封禁</p>
            <br/>
            <p v-if="updateEmail"><strong>邮箱：</strong><input v-model="user.email" style="width: 130px"><a @click="updateInfo">&nbsp;&nbsp;&nbsp;&nbsp;保存</a></p>
            <p v-else-if="!updateEmail"><strong>邮箱：</strong>{{ this.user.email }}<a v-if="this.user.email == null" @click="emailCheck">添加</a><a v-else @click="emailCheck">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;修改</a></p>
            <br/>
            <p><strong>注册时间：</strong>{{ this.user.createTime }}</p>
            <br/>
            <div class="user-exit-button">
              <u><a @click="logout()">退出登录</a></u>
            </div>
            <br/>
          </div>

        </div>
      </div>
    </div>
    <footer>
      <p>Design by <a>张海洋</a></p>
      <p>备案号：<a href="https://beian.miit.gov.cn/">豫ICP备2023012308号</a></p>
    </footer>
  </div>
</template>

<script>
import api from '../assets/js/axios';

export default {
  data() {
    return {
      user: {
        userId: null,
        icon: null,
        nickName: '',
        userName: null,
        createTime: '',
        rule: -1,
        email: ''

      },
      updateNickName: false,
      updateEmail: false
    }
  },
  created() {
    this.checkUser();
  },
  methods: {
    logout() {
      sessionStorage.clear();
      localStorage.clear();
      alert("退出成功！");
      this.$router.push("/")
    },
    checkUser() {
      let token = sessionStorage.getItem("myBlogUserToken");
      if (token == null) {
        token = localStorage.getItem("myBlogUserToken");
      }
      this.token = token
      console.log(this.token)
      if (token != null) {
        const hot = "/user/selectUserInfo";
        const config = {
          headers: {
            'Content-Type': 'application/json', // 设置请求头的 Content-Type
            'token': token
          }
        };
        api.get(hot, config).then(
            response => {
              if (response.state == 200){
                this.user.userId = response.data.userId;
                this.user.icon = response.data.icon;
                this.user.userName = response.data.userName;
                this.user.nickName = response.data.nickName;
                this.user.rule = response.data.rule;
                this.user.createTime = response.data.createTime;
                if (response.data.email != null){
                  this.user.email = response.data.email
                }
              }else if(response.state == 403){
                sessionStorage.clear();
                localStorage.clear();
                alert("登录过期...");
                this.$router.push("/")
              }

            }
        ).catch(
            error => {
              console.log(error)
            }
        )
      }
    },
    nickNameCheck(){
      this.updateNickName = true;
    },
    emailCheck(){
      this.updateEmail = true;
    },
    updateInfo(){
      const tokenValue = this.token || ''
      const config = {
        headers: {
          'Content-Type': 'application/json', // 设置请求头的 Content-Type
          'token': tokenValue
        }
      };
      const url = "/user/updateUserInfo";
      let user = {
        userId: this.user.userId,
        nickName: this.user.nickName,
        email: this.user.email
      }
      api.post(url,user,config).then(
          response => {
            if (response.state == 200){
              location.reload();
            }else {
              alert(response.msg)
            }
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    }
  }
}
</script>

