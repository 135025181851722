<template>
  <div>
    <header>
      <div class="title-block">
        <div class="logo">
          <a>一路同行</a>
          <br>
          <!-- 使用v-if和v-else根据isLoggedIn的值来显示不同的内容 -->
          <a class="logo-user" v-if="user != null" href="#"><router-link to="/personalInfo">欢迎您  “{{ user.nickName }}”</router-link></a>
          <a class="logo-user" v-else href="#"><router-link to="/login">待登录...</router-link></a>
        </div>
      </div>
      <nav>
        <ul id="starlist">
          <li><router-link to="/">网站首页</router-link></li>
          <li><router-link to="/about">关于我</router-link></li>
          <li><router-link to="/listBlog">博客日记</router-link></li>
          <li><router-link to="/listSlow">慢生活</router-link></li>
          <li><router-link to="/photoAlbum">相册</router-link></li>
          <li><router-link to="/commentArea">评论区</router-link></li>
          <li><router-link to="/time">时间轴</router-link></li>
        </ul>
      </nav>
    </header>
    <div class="box">
      <div class="place" id="pageContents">

        <a href="#" target="_blank" id="pagecurrent">心得笔记</a>

        <a href="#" target="_blank">CSS3|Html5</a>

        <a href="#" target="_blank">网站建设</a>

        <a href="#" target="_blank">JS经典实例</a>

        <a href="#" target="_blank">推荐工具</a>
      </div>
      <div class="blogs">
        <div class="bloglist" v-for="(item, index) in blogList" :key="index">
          <h2><a @click="viewArticle(item.articleId)">{{item.title}}</a></h2>
          <p v-html="item.content"></p>
        </div>
        <div class="pagelist">
          <span>总数：<b>{{ totalRecords }}</b></span>  &nbsp;&nbsp;
          <span>当前页：<b>{{ currentPage }}</b></span>  &nbsp;&nbsp;<br>
          <a v-if="currentPage > 1" href="#" @click.prevent="prevPage">上一页</a>
          <a
              v-for="page in pages"
              :key="page"
              href="#"
              @click.prevent="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
          >{{ page }}</a>
          <a v-if="currentPage < totalPages" href="#" @click.prevent="nextPage">下一页</a>
          <a @click.prevent="jumpToPage(1)" href="#" >首页</a>
          <a @click.prevent="jumpToPage(totalPages)" href="#" >尾页</a>
        </div>
      </div>
      <aside>
        <div class="ztbox">
          <ul>
            <li> <router-link to="listDefault">个人项目</router-link></li>
            <li> <router-link to="listDefault">经典项目</router-link></li>
            <li> <router-link to="listDefault">心得笔记</router-link></li>
            <li> <router-link to="listDefault">Java|Jvm</router-link></li>
            <li> <router-link to="listDefault">我的天地</router-link></li>
          </ul>
        </div>
        <div class="paihang">
          <h2>点击排行</h2>
          <ul>
            <li  v-for="(item, index) in hotList" :key="index">
              <a @click="viewArticle(item.articleId)">{{item.title}}</a></li>
          </ul>
        </div>
        <div class="paihang">
          <h2>站长推荐</h2>
          <ul>
            <li  v-for="(item, index) in recomList" :key="index">
              <a @click="viewArticle(item.articleId)">{{item.title}}</a></li>
          </ul>
        </div>
        <div class="paihang">
          <h2>友情链接</h2>
          <ul>
            <li><a href="#">张海洋的个人博客</a></li>
            <li><a href="http://www.yangqq.com">青姐的个人博客</a></li>
          </ul>
        </div>
      </aside>
    </div>
    <footer>
      <p>Design by <a>张海洋</a> </p>
      <p>备案号：<a href="https://beian.miit.gov.cn/">豫ICP备2023012308号</a></p>
    </footer>
  </div>
</template>

<script>
import api from '../assets/js/axios';
export default {
  data() {
    return {
      token: null,
      currentPage: 1, // 当前页码
      pageSize: 5,   // 每页显示条数
      totalRecords: 0, // 总记录数
      blogList: null,
      hotList: {},
      recomList: {},
      user: null
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalRecords / this.pageSize);
    },
    pages() {
      const start = Math.max(1, this.currentPage - 2);
      const end = Math.min(this.totalPages, this.currentPage + 2);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }
  },
  created() {
    this.getPageList();
    this.checkUser();
    const tokenValue = this.token || ''
    const config = {
      headers: {
        'Content-Type': 'application/json', // 设置请求头的 Content-Type
        'token': tokenValue
      }
    };
    const hot = "/article/selectPageByHot";
    api.get(hot,config).then(
        response => {
          this.hotList = response.data.records
        }
    ).catch(
        error => {
          console.log(error)
        }
    )

    const isRecom = "/article/selectPageCommon";
    let article = {
      "isRecom": 1
    };
    api.post(isRecom,article,config).then(
        response => {
          this.recomList = response.data.records
        }
    ).catch(
        error => {
          console.log(error)
        }
    )


  },
  methods: {
    viewArticle(articleId){
      this.$router.push("/articleInfo?articleId="+articleId)
    },
    async getPageList() {
      const tokenValue = this.token || ''
      const config = {
        headers: {
          'Content-Type': 'application/json', // 设置请求头的 Content-Type
          'token': tokenValue
        }
      };
      const url = "/article/selectPageCommon?"+"pageNum="+this.currentPage+"&pageSize="+this.pageSize;
      const article = {
        "articleType": 7
      };
      api.post(url,article,config).then(
          response => {
            this.blogList = response.data.records
            this.totalRecords = response.data.total
            console.log(this.blogList)
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
      this.getPageList();
    },
    jumpToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    } ,
    checkUser(){
      let token = sessionStorage.getItem("myBlogUserToken");
      if (token == null) {
        token = localStorage.getItem("myBlogUserToken");
      }
      this.token = token
      console.log(token)
      if (token != null) {
        const hot = "/user/selectUserInfo";
        const config = {
          headers: {
            'Content-Type': 'application/json', // 设置请求头的 Content-Type
            'token': token
          }
        };
        api.get(hot,config).then(
            response => {
              this.user = response.data
            }
        ).catch(
            error => {
              console.log(error)
            }
        )
      }
    }
  }
}
</script>

