<template>
  <div class="login-container">
    <form :rules="rules">
      <div class="login-box">
        <h1>注册</h1>
        <div class="input-group">
          <label for="username">用户名:</label>
          <input @change="checkUsername" v-model="userName" type="text" id="username" name="username" required>
          <span v-if="usernameCheck != null && usernameCheck" class="success">✔</span>
          <span v-if="usernameCheck != null && !usernameCheck" class="error">X</span>
        </div>
        <div class="input-group">
          <label for="password">密码:</label>
          <input v-model="password" type="password" id="password" name="password" required>
          <span v-if="passwordCheck != null && passwordCheck" class="success">✔</span>
          <span v-if="passwordCheck != null && !passwordCheck" class="error">X</span>
        </div>
        <div class="input-group">
          <label for="password">重复密码:</label>
          <input @change="checkPassword" v-model="retypePassword" type="password" id="retypePassword"
                 name="retypePassword" required>
          <span v-if="passwordCheck != null && passwordCheck" class="success">✔</span>
          <span v-if="passwordCheck != null && !passwordCheck" class="error">X</span>
        </div>

        <button @click.prevent="register">Register</button>
        <br/><br/>
      </div>
    </form>
  </div>
</template>

<script>
import api from '../assets/js/axios';

export default {
  data() {
    return {
      rememberMe: false,
      userName: null,
      password: null,
      retypePassword: null,
      usernameCheck: null,
      passwordCheck: null,
      rules: {
        userName: [
          {
            required: true,
            message: "请输入排序权重",
            trigger: "blur",
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    checkUsername() {
      if (this.userName.length > 3 && this.userName.length < 16) {
        this.usernameCheck = true;
      } else {
        this.usernameCheck = false;
      }
    },
    checkPassword() {
      if (this.password != null && this.password != "" && this.password == this.retypePassword) {
        this.passwordCheck = true;
      } else {
        this.passwordCheck = false;
      }
    },
    register() {
      if (!this.passwordCheck && !this.usernameCheck) {
        alert("请检查输入的信息！！")
        return;
      }
      const url = "/user/register";
      let user = {
        "userName": this.userName,
        "password": this.password
      };
      api.post(url, user).then(
          response => {
            if (response.state == 200) {
              alert(response.msg)
              this.changeView("/login")
            } else {
              alert(response.msg);
            }
          }
      ).catch(
          error => {
            console.log(error)
          }
      )


    },
    changeView(url, queryParams) {
      this.$router.push({
        path: url,
        query: queryParams,
      });
    },
  }
}
</script>

