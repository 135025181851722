// api.js

import axios from 'axios';

const api = axios.create({
  baseURL: '/api', // 根据您的实际情况配置基本URL
});

// 请求拦截器
api.interceptors.request.use(
  (config) => {
    // 在发送请求之前可以进行一些处理，例如添加token等
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
api.interceptors.response.use(
  (response) => {
    // 对响应数据进行处理
    return response.data;
  },
  (error) => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

export default api;
