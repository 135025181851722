<template>
  <div>
    <header>
      <div class="title-block">
        <div class="logo">
          <a>关于我</a>
          <br>
          <!-- 使用v-if和v-else根据isLoggedIn的值来显示不同的内容 -->
          <a class="logo-user" v-if="user != null" href="#"><router-link to="/personalInfo">欢迎您  “{{ user.nickName }}”</router-link></a>
          <a class="logo-user" v-else href="#"><router-link to="/login">待登录...</router-link></a>
        </div>
      </div>
  <nav>
    <ul id="starlist">
      <li><router-link to="/">网站首页</router-link></li>
        <li><router-link to="/about">关于我</router-link></li>
        <li><router-link to="/listBlog">博客日记</router-link></li>
        <li><router-link to="/photoAlbum">相册</router-link></li>
        <li><router-link to="/commentArea">评论区</router-link></li>
        <li><router-link to="/time">时间轴</router-link></li>
    </ul>
  </nav>
</header>
<div class="box">
  <div class="newsview">
    <div class="news_infos">
      <h2>关于我</h2>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;张海洋，Binny，男，一个伪00后站长，23年入行。热爱游戏、编程、吃，喜欢捣鼓计算机技术，目标是成为Java架构师，希望未来能为Java社区的成长贡献出自己的一部分力量。
        <br>
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;说来惭愧，至求学以来，对于学业上大器晚成，而毕业时又正赶上互联网寒。好在运气还算可以，找了个不错的工作，在此期间也有时间更好提升自己。
        <br>
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在这求学至目前这十几年里，见证了中国互联网行业壮观崛起，也亲身体会到了互联网行业的迅速降温。但凭借着对科技与互联网行业的热爱，抱着与其怨天尤人，不如放手一搏的心态，进入了这里。希望以后能在这个自己热爱的行业留下自己的一抹痕迹。
        <br>
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;最后我想说的是：愿世界和平安宁，愿祖国繁荣昌盛！
        <br>
        <br>
        <br>
      </p>
      <h2>About my blog</h2>
      <p>域 名：www.zhanghy.cc 注册于2024年09月06日&nbsp;</p>
      <br>
      <p>服务器：阿里云服务器&nbsp;&nbsp;<a href="https://www.aliyun.com/"><span style="color:#FF0000;"><strong>前往阿里云&gt;&gt;</strong></span></a></p>
      <br>
      <p>网站支持：<a href="http://www.yangqq.com" target="_blank"><span style="color:#FF0000;"><strong>青姐的博客&gt;&gt;</strong></span></a></p>
      <br>
      <p>备案号： 豫ICP备2023012308号-4</p>
      <br>
      <p>程 序：开发中...</p>
      <br>
    </div>
  </div>
</div>
<footer>
  <p>Design by <a>张海洋</a> </p>
  <p>备案号：<a href="https://beian.miit.gov.cn/">豫ICP备2023012308号</a></p>
</footer>
  </div>
</template>

<script>
import api from '../assets/js/axios';
export default {
  data() {
      return {
        user: null
      }
    },
    created() {
      this.checkUser();
    },    
    methods: {
      checkUser(){
        let token = sessionStorage.getItem("myBlogUserToken");
        if (token == null) {
          token = localStorage.getItem("myBlogUserToken");
        }
        console.log(token)
        if (token != null) {
          const hot = "/user/selectUserInfo";
          const config = {
            headers: {
              'Content-Type': 'application/json', // 设置请求头的 Content-Type
              'token': token
            }
          };
          api.get(hot,config).then(
            response => {
              if (response.state == 200){
                this.user = response.data
              } else if(response.state == 403){
                sessionStorage.clear();
                localStorage.clear();
                alert("登录过期...")
              }
            }
          ).catch(
            error => {
              console.log(error)
            }
          )
        }
      }
    }
  }
</script>

