<template>
  <div>
    <header>
      <div class="title-block">
        <div class="logo">
          <a>时间轴</a>
          <br>
          <!-- 使用v-if和v-else根据isLoggedIn的值来显示不同的内容 -->
          <a class="logo-user" v-if="user != null" href="#"><router-link to="/personalInfo">欢迎您  “{{ user.nickName }}”</router-link></a>
          <a class="logo-user" v-else href="#"><router-link to="/login">待登录...</router-link></a>
        </div>
      </div>
      <nav>
        <ul id="starlist">
          <li>
            <router-link to="/">网站首页</router-link>
          </li>
          <li>
            <router-link to="/about">关于我</router-link>
          </li>
          <li>
            <router-link to="/listBlog">博客日记</router-link>
          </li>
          <li><router-link to="/photoAlbum">相册</router-link></li>
          <li>
            <router-link to="/commentArea">评论区</router-link>
          </li>
          <li>
            <router-link to="/time">时间轴</router-link>
          </li>
        </ul>
      </nav>
    </header>
    <article>
      <div class="timebox">
        <ul>
          <li v-for="(item, index) in articleList" :key="index">
            <span>{{ item.createTime }}</span><i><a @click="viewArticle(item.articleId)">{{ item.title }}</a></i></li>
        </ul>
      </div>
      <div class="pagelist">
        <span>总数：<b>{{ totalRecords }}</b></span> &nbsp;&nbsp;
        <span>当前页：<b>{{ currentPage }}</b></span> &nbsp;&nbsp;<br>
        <a v-if="currentPage > 1" href="#" @click.prevent="prevPage">上一页</a>
        <a
            v-for="page in pages"
            :key="page"
            href="#"
            @click.prevent="changePage(page)"
            :class="{ 'active-page': currentPage === page }"
        >{{ page }}</a>
        <a v-if="currentPage < totalPages" href="#" @click.prevent="nextPage">下一页</a>
        <a @click.prevent="jumpToPage(1)" href="#">首页</a>
        <a @click.prevent="jumpToPage(totalPages)" href="#">尾页</a>
      </div>
    </article>
    <footer>
      <p>Design by <a>张海洋</a> <a
          href="https://beian.miit.gov.cn/">豫ICP备2023012308号</a></p>
    </footer>
  </div>
</template>

<script>
import api from '../assets/js/axios';

export default {
  data() {
    return {
      token: null,
      currentPage: 1, // 当前页码  
      pageSize: 20,   // 每页显示条数  
      totalRecords: 0, // 总记录数
      articleList: null,
      user: null
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalRecords / this.pageSize);
    },
    pages() {
      const start = Math.max(1, this.currentPage - 2);
      const end = Math.min(this.totalPages, this.currentPage + 2);
      return Array.from({length: end - start + 1}, (_, i) => start + i);
    }
  },
  created() {
    this.checkUser();
    this.getPageList();
  },
  methods: {
    viewArticle(articleId) {
      this.$router.push("/articleInfo?articleId=" + articleId)
    },
    async getPageList() {
      const tokenValue = this.token || ''
      const config = {
        headers: {
          'Content-Type': 'application/json', // 设置请求头的 Content-Type
          'token': tokenValue
        }
      };
      const url = "/article/selectPageCommon?" + "pageNum=" + this.currentPage + "&pageSize=" + this.pageSize;
      let article = {
        "state": 1
      };
      api.post(url, article,config).then(
          response => {
            this.articleList = response.data.records
            this.totalRecords = response.data.total
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
      this.getPageList();
    },
    jumpToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    checkUser() {
      let token = sessionStorage.getItem("myBlogUserToken");
      if (token == null) {
        token = localStorage.getItem("myBlogUserToken");
      }
      this.token = token
      console.log(token)
      if (token != null) {
        const hot = "/user/selectUserInfo";
        const config = {
          headers: {
            'Content-Type': 'application/json', // 设置请求头的 Content-Type
            'token': token
          }
        };
        api.get(hot, config).then(
            response => {
              if (response.state == 200){
                this.user = response.data
              } else if(response.state == 403){
                sessionStorage.clear();
                localStorage.clear();
                alert("登录过期")
              }
            }
        ).catch(
            error => {
              console.log(error)
            }
        )
      }
    }
  }
}
</script>
