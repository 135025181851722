<template>
  <div>
    <header>
      <div class="title-block">
        <div class="logo">
          <a>建筑工地</a>
          <br>
          <!-- 使用v-if和v-else根据isLoggedIn的值来显示不同的内容 -->
          <a class="logo-user" v-if="user != null" href="#"><router-link to="/personalInfo">欢迎您  “{{ user.nickName }}”</router-link></a>
          <a class="logo-user" v-else href="#"><router-link to="/login">待登录...</router-link></a>
        </div>
      </div>
      <nav>
        <ul id="starlist">
          <li><router-link to="/">网站首页</router-link></li>
          <li><router-link to="/about">关于我</router-link></li>
          <li><router-link to="/listBlog">博客日记</router-link></li>
          <li><router-link to="/listSlow">慢生活</router-link></li>
          <li><router-link to="/photoAlbum">相册</router-link></li>
          <li><router-link to="/commentArea">评论区</router-link></li>
          <li><router-link to="/time">时间轴</router-link></li>
        </ul>
      </nav>
    </header>
    <div class="box">
      <div class="newsview">
        <br/><br/><br/><br/><br/><br/><br/>
        <h1>页面建设中，敬请期待......</h1>
        <br/><br/><br/><br/><br/><br/><br/>
      </div>
    </div>
    <footer>
      <p>Design by <a>张海洋</a></p>
      <p>备案号：<a href="https://beian.miit.gov.cn/">豫ICP备2023012308号</a></p>
    </footer>
  </div>
</template>

<script>
import api from '../assets/js/axios';

export default {
  data() {
    return {
      user: null
    }
  },
  created() {
    this.getPageList();
    this.checkUser();
  },
  methods: {
    async getPageList() {

    },
    checkUser() {
      let token = sessionStorage.getItem("myBlogUserToken");
      if (token == null) {
        token = localStorage.getItem("myBlogUserToken");
      }
      console.log(token)
      if (token != null) {
        const hot = "/user/selectUserInfo";
        const config = {
          headers: {
            'Content-Type': 'application/json', // 设置请求头的 Content-Type
            'token': token
          }
        };
        api.get(hot, config).then(
            response => {
              this.user = response.data
            }
        ).catch(
            error => {
              console.log(error)
            }
        )
      }
    }
  }
}
</script>

