<template> 
    <div class="login-container">  
        <form>  
            <div class="login-box">  
                <h1>登录</h1>  
                <div class="input-group">  
                    <label for="username">用户名:</label>  
                    <input v-model="userName" type="text" id="username" name="username" required>  
                </div>  
                <div class="input-group">  
                    <label for="password">密码:</label>  
                    <input v-model="password" type="password" id="password" name="password" required>  
                </div>
                <div class="checkbox-group">
                    <input type="checkbox" id="remember-me" v-model="rememberMe" name="remember-me">
                    <label for="remember-me">记住我</label>
                </div>
                <button @click.prevent="login">Login</button><br/><br/>
                <u><router-link to="/register">Register</router-link></u>
            </div>
        </form>  
    </div>  
</template>

<script>
import api from '../assets/js/axios';
export default {
  data() {
      return {
        rememberMe: false,
        userName: null,
        password: null
      }
    },
    created() {
    },    
    methods: {
      login() {
        const url = "/user/login"; 
        let user = {  
          "userName": this.userName,
          "password": this.password
        };  
        api.post(url,user).then(
          response => {
            if (response.state == 200) {
                if (this.rememberMe) {
                  localStorage.setItem("myBlogUserToken",response.data);
                }else{
                  sessionStorage.setItem("myBlogUserToken",response.data); 
                }
                this.changeView("/")
            }else{
                alert(response.msg);
            }
          }
        ).catch(
          error => {
            console.log(error)
          }
        )
      },
      changeView(url, queryParams) {
        this.$router.push({
          path: url,
          query: queryParams,
        });
      },
    }
  }
</script>

