<template>
  <div>
  <header>
    <div class="title-block">
      <div class="logo">
        <a>张海洋个人博客</a>
        <br>
        <!-- 使用v-if和v-else根据isLoggedIn的值来显示不同的内容 -->
        <a class="logo-user" v-if="user != null" href="#"><router-link to="/personalInfo">欢迎您  “{{ user.nickName }}”</router-link></a>
        <a class="logo-user" v-else href="#"><router-link to="/login">待登录...</router-link></a>
      </div>
    </div>

    <nav>
      <ul id="starlist">
        <li><router-link to="/">网站首页</router-link></li>
        <li><router-link to="/about">关于我</router-link></li>
        <li><router-link to="/listBlog">博客日记</router-link></li>
        <li><router-link to="/photoAlbum">相册</router-link></li>
        <li><router-link to="/commentArea">评论区</router-link></li>
        <li><router-link to="/time">时间轴</router-link></li>
        </ul>
    </nav>


  </header>

  <div class="box">
    <div class="newsbox f_l ">
      <div class="newstitle"><span><router-link to="listBlog">+</router-link></span><b>博客日记</b></div>
      <ul class="newsli">
        <li  v-for="(item, index) in blogList" :key="index">
          <a target="_blank" @click="viewArticle(item.articleId)">{{item.title}}</a></li>
      </ul>
    </div>
    <div class="newsbox f_r ">
      <div class="newstitle"><span><router-link to="listStudy">+</router-link></span><b>学无止境</b></div>
      <ul class="newsli">
        <li  v-for="(item, index) in programmeList" :key="index">
          <a target="_blank" @click="viewArticle(item.articleId)">{{item.title}}</a></li>
      </ul>
    </div>
    <div class="blank"></div>
    <div class="sbox f_l"> <span>旅途风景</span>
      <h2>世界这么大，总要出去看看的！</h2>
      <p>每一次旅行，都是一次心灵的洗礼。感受大自然的鬼斧神工，领略不同文化的魅力。而我，来了！</p>
      <a class="read"><router-link to="listView">点击前往</router-link></a> </div>
    <div class="sbox f_l ml"> <span>生活点滴</span>
      <h2>生活可有趣了，简单且充满惊喜。</h2>
      <p>每天醒来，都是新的开始，每个瞬间，都值得留念。无论是清晨的阳光，还是夜晚的星辰，都要用心感受。</p>
      <a class="read"><router-link to="listSlow">点击前往</router-link></a> </div>
    <div class="sbox f_r"> <span>一路同行</span>
      <h2>或良师或益友，或者...那个Ta？</h2>
      <p>陪伴我们成长，指引我们前行，教会我们进步。感恩每一次相遇，感谢我认识的这个温暖的你。</p>
      <a class="read"><router-link to="listFriend">点击前往</router-link></a> </div>
    <div class="blank"></div>
    <div class="blogs">
      <div class="bloglist" v-for="(item, index) in allList" :key="index">
        <h2><a title="#" @click="viewArticle(item.articleId)">{{item.title}}</a></h2>
        <p v-html="item.content"></p>
      </div>

    </div>
    <aside>
      <div class="ztbox">
        <ul>
          <li> <router-link to="listDefault">个人项目</router-link></li>
          <li> <router-link to="listDefault">经典项目</router-link></li>
          <li> <router-link to="listDefault">心得笔记</router-link></li>
          <li> <router-link to="listDefault">Java|Jvm</router-link></li>
          <li> <router-link to="listDefault">我的天地</router-link></li>
        </ul>
      </div>
      <div class="paihang">
        <h2>点击排行</h2>
        <ul>
          <li  v-for="(item, index) in hotList" :key="index">
          <a target="_blank" @click="viewArticle(item.articleId)">{{item.title}}</a></li>
        </ul>
      </div>
      <div class="paihang">
        <h2>站长推荐</h2>
        <ul>
          <li  v-for="(item, index) in recomList" :key="index">
          <a @click="viewArticle(item.articleId)">{{item.title}}</a></li>
        </ul>
      </div>
      <div class="paihang">
        <h2>友情链接</h2>
        <ul>
          <li><a href="#">张海洋的个人博客</a></li>
          <li><a href="http://www.yangqq.com">青姐的个人博客</a></li>
        </ul>
      </div>
    </aside>
  </div>
  <footer>
    <p>Design by <a>张海洋</a> </p>
    <p>备案号：<a href="https://beian.miit.gov.cn/">豫ICP备2023012308号</a></p>
  </footer>
  <router-view/>
  </div>
  </template>

  <script>
  import api from '../assets/js/axios';

  export default {
    name: 'IndexPage',
    data() {
      return {
        token: null,
        programmeList: {},
        blogList: {},
        allList: {},
        hotList: {},
        recomList: {},
        user: null
      }
    },
    created() {
      this.checkUser();
      this.getPageList();
    },
    methods: {
      viewArticle(articleId){
        this.$router.push("/articleInfo?articleId="+articleId)
      },
      getPageList() {
        const programme = "/article/selectPageByHot?articleType=2";
        const tokenValue = this.token || ''
        const config = {
            headers: {
              'Content-Type': 'application/json', // 设置请求头的 Content-Type
              'token': tokenValue
            }
          };
        api.get(programme,config).then(
          response => {
            this.programmeList = response.data.records
          }
        ).catch(
          error => {
            console.log(error)
          }
        )
        const blog = "/article/selectPageByHot?articleType=5";
        api.get(blog,config).then(
          response => {
            this.blogList = response.data.records
          }
        ).catch(
          error => {
            console.log(error)
          }
        )
        const hot = "/article/selectPageByHot";
        api.get(hot,config).then(
          response => {
            this.hotList = response.data.records
          }
        ).catch(
          error => {
            console.log(error)
          }
        )
        const all = "/article/selectPageCommon";
        let allArticle = {};
        api.post(all,allArticle,config).then(
          response => {
            this.allList = response.data.records
          }
        ).catch(
          error => {
            console.log(error)
          }
        )
        const isRecom = "/article/selectPageCommon";
        let article = {
          "isRecom": 1
        };
        api.post(isRecom,article,config).then(
          response => {
            this.recomList = response.data.records
          }
        ).catch(
          error => {
            console.log(error)
          }
        )
      },
      checkUser(){
        let token = sessionStorage.getItem("myBlogUserToken");
        if (token == null) {
          token = localStorage.getItem("myBlogUserToken");
        }
        this.token = token
        if (token != null) {
          const hot = "/user/selectUserInfo";
          const config = {
            headers: {
              'Content-Type': 'application/json', // 设置请求头的 Content-Type
              'token': token
            }
          };
          api.get(hot,config).then(
            response => {
              if (response.state == 200){
                this.user = response.data
              } else if(response.state == 403){
                sessionStorage.clear();
                localStorage.clear();
                alert("登录过期")
              }
            }
          ).catch(
            error => {
              console.log(error)
            }
          )
        }
      }
    }
  }
  </script>
  
  