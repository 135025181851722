import Vue from 'vue';  
import Router from 'vue-router';  
import Home from '@/components/Index.vue';  
import AboutMe from '@/components/AboutMe.vue';   
import ListBlog from '@/components/ListBlog.vue'; 
import ListView from '@/components/ListView.vue';
import ListFriend from '@/components/ListFriend.vue'; 
import TimerShaft from '@/components/TimerShaft.vue';
import ListStudy from '@/components/ListStudy.vue';
import CommentArea from '@/components/CommentArea.vue';
import ListSlow from '@/components/ListSlow.vue';
import ArticleInfo from '@/components/ArticleInfo.vue';
import LoginPage from '@/components/LoginPage.vue';
import PersonalInfo from '@/components/PersonalInfo.vue';
import ListDefault from "@/components/ListDefault.vue";
import RegisterPage from "@/components/RegisterPage.vue";
import PhotoAlbum from "@/components/PhotoAlbum.vue";
import PhotosInfo from "@/components/PhotosInfo.vue";
  
Vue.use(Router);  
  
export default new Router({  
  scrollBehavior (to, from, savedPosition) {  
    if (savedPosition) {  
      return savedPosition;  
    } else {  
      return { x: 0, y: 0 };  
    }  
  },
  routes: [
    {  
      path: '/',  
      name: '首页',  
      component: Home  
    },    
    {  
      path: '/about',  
      name: '关于我',  
      component: AboutMe  
    },    
    {  
      path: '/listBlog',  
      name: '博客日记',  
      component: ListBlog  
    },    
    {  
      path: '/listView',  
      name: '旅途风景',  
      component: ListView  
    },    
    {  
      path: '/listFriend',  
      name: '生活点滴',  
      component: ListFriend  
    },    
    {  
      path: '/time',  
      name: '一路同行',  
      component: TimerShaft  
    },    
    {  
      path: '/listStudy',  
      name: '学无止境',  
      component: ListStudy  
    },    
    {  
      path: '/commentArea',  
      name: '评论区',  
      component: CommentArea  
    },    
    {  
      path: '/listSlow',  
      name: '慢生活',  
      component: ListSlow  
    },    
    {  
      path: '/articleInfo',  
      name: '文章',  
      component: ArticleInfo  
    },    
    {  
      path: '/login',  
      name: '登录',  
      component: LoginPage  
    },
    {
      path: '/register',
      name: '注册',
      component: RegisterPage
    },
    {
      path: '/personalInfo',
      name: '个人信息',
      component: PersonalInfo
    },
    {
      path: '/listDefault',
      name: '建筑工地',
      component: ListDefault
    },
    {
      path: '/photoAlbum',
      name: '时光相册',
      component: PhotoAlbum
    },
    {
      path: '/photoInfo',
      name: '影集详情',
      component: PhotosInfo
    }
  ]  
});