<template>
  <div>
    <header>
      <div class="title-block">
        <div class="logo">
          <a>相册详细</a>
          <br>
          <!-- 使用v-if和v-else根据isLoggedIn的值来显示不同的内容 -->
          <a class="logo-user" v-if="user != null" href="#"><router-link to="/personalInfo">欢迎您  “{{ user.nickName }}”</router-link></a>
          <a class="logo-user" v-else href="#"><router-link to="/login">待登录...</router-link></a>
        </div>
      </div>
  <nav>
    <ul id="starlist">
      <li><router-link to="/">网站首页</router-link></li>
        <li><router-link to="/about">关于我</router-link></li>
        <li><router-link to="/listBlog">博客日记</router-link></li>
        <li><router-link to="/photoAlbum">相册</router-link></li>
        <li><router-link to="/commentArea">评论区</router-link></li>
        <li><router-link to="/time">时间轴</router-link></li>
    </ul>
  </nav>
</header>
<div class="box">
  <div class="screen-div">
    <h2>{{photoInfo.title}}</h2>
    <h4>{{photoInfo.createTime}}</h4>
    <p v-html="photoInfo.caption"></p>
    <div class="photo-info-container">
      <div v-for="(photo, index) in photoInfo.photoList" :key="index">
        <img :src="photo" @click="viewPhoto(photo)" alt="photo" style="width: 80%; height: 80%;">
      </div>
    </div>
  </div>
</div>
<footer>
  <p>Design by <a>张海洋</a> </p>
  <p>备案号：<a href="https://beian.miit.gov.cn/">豫ICP备2023012308号</a></p>
</footer>
  </div>
</template>

<script>
import api from '../assets/js/axios';
export default {
  data() {
      return {
        token: null,
        photoInfo: null,
        user: null
      }
    },
    created() {
      this.checkUser();
      this.photoInfoInit();
    },    
    methods: {
      viewPhoto(photoUrl){
        window.location.href = photoUrl;
      },
      photoInfoInit(){
        const tokenValue = this.token || ''
        const config = {
          headers: {
            'Content-Type': 'application/json', // 设置请求头的 Content-Type
            'token': tokenValue
          }
        };
        const url = "/photo/selectById?photoId="+this.$route.query.photoId;
        api.get(url,config).then(
            response => {
              if (response.state == 200){
                this.photoInfo = response.data
              }else {
                alert(response.msg)
              }
            }
        ).catch(
            error => {
              console.log(error)
            }
        )
      },
      checkUser(){
        let token = sessionStorage.getItem("myBlogUserToken");
        if (token == null) {
          token = localStorage.getItem("myBlogUserToken");
        }
        this.token = token
        if (token != null) {
          const hot = "/user/selectUserInfo";
          const config = {
            headers: {
              'Content-Type': 'application/json', // 设置请求头的 Content-Type
              'token': token
            }
          };
          api.get(hot,config).then(
            response => {
              if (response.state == 200){
                this.user = response.data
              } else if(response.state == 403){
                sessionStorage.clear();
                localStorage.clear();
                alert("登录过期")
              }
            }
          ).catch(
            error => {
              console.log(error)
            }
          )
        }
      }
      }
  }
</script>

