<template>
  <div>
    <header>
      <div class="title-block">
        <div class="logo">
          <a>相册</a>
          <br>
          <!-- 使用v-if和v-else根据isLoggedIn的值来显示不同的内容 -->
          <a class="logo-user" v-if="user != null" href="#"><router-link to="/personalInfo">欢迎您  “{{ user.nickName }}”</router-link></a>
          <a class="logo-user" v-else href="#"><router-link to="/login">待登录...</router-link></a>
        </div>
      </div>
      <nav>
        <ul id="starlist">
          <li>
            <router-link to="/">网站首页</router-link>
          </li>
          <li>
            <router-link to="/about">关于我</router-link>
          </li>
          <li>
            <router-link to="/listBlog">博客日记</router-link>
          </li>
          <li>
            <router-link to="/time">相册</router-link>
          </li>
          <li>
            <router-link to="/commentArea">评论区</router-link>
          </li>
          <li>
            <router-link to="/time">时间轴</router-link>
          </li>
        </ul>
      </nav>
    </header>
    <div class="box">
      <div class="blank"></div>
      <div class="message-board" v-for="(item, index) in photoList" :key="index">
        <div class="message-header">
          <a @click="viewPhoto(item.photoId)"><h3>{{ item.title }}</h3></a>
          <span class="time">{{ item.createTime }}</span>
        </div>
        <div class="photo-container-flex">
          <div v-for="(photo, index) in item.photoList" :key="index" class="photo-container">
            <img :src="photo" alt="Photo" style="width: 100px; height: 100px;">
          </div>
          <div class="photo-router-link" v-if="item.photoNum > 6">
            <a @click="viewPhoto(item.photoId)">查看全部>>></a>
          </div>
        </div>
      </div>
      <div class="pagelist">
        <span>总数：<b>{{ totalRecords }}</b></span> &nbsp;&nbsp;
        <span>当前页：<b>{{ currentPage }}</b></span> &nbsp;&nbsp;<br>
        <a v-if="currentPage > 1" href="#" @click.prevent="prevPage">上一页</a>
        <a
            v-for="page in pages"
            :key="page"
            href="#"
            @click.prevent="changePage(page)"
            :class="{ 'active-page': currentPage === page }"
        >{{ page }}</a>
        <a v-if="currentPage < totalPages" href="#" @click.prevent="nextPage">下一页</a>
        <a @click.prevent="jumpToPage(1)" href="#">首页</a>
        <a @click.prevent="jumpToPage(totalPages)" href="#">尾页</a>
      </div>
    </div>
    <footer>
      <p>Design by <a>张海洋</a></p>
      <p>备案号：<a href="https://beian.miit.gov.cn/">豫ICP备2023012308号</a></p>
    </footer>
  </div>
</template>

<script>
import api from '../assets/js/axios';

export default {
  data() {
    return {
      currentPage: 1, // 当前页码
      pageSize: 5,   // 每页显示条数
      totalRecords: 0, // 总记录数
      photoList: null,
      user: null,
      token: null
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalRecords / this.pageSize);
    },
    pages() {
      const start = Math.max(1, this.currentPage - 2);
      const end = Math.min(this.totalPages, this.currentPage + 2);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }
  },
  created() {
    this.checkUser();
    this.getPageList();
  },
  methods: {
    viewPhoto(photoId){
      this.$router.push("/photoInfo?photoId="+photoId)
    },
    async getPageList() {
      const url = "/photo/selectPage?pageNum=" + this.currentPage + "&pageSize=" + this.pageSize;
      const tokenValue = this.token || ''
      const config = {
        headers: {
          'Content-Type': 'application/json', // 设置请求头的 Content-Type
          'token': tokenValue
        }
      };
      api.get(url, config).then(
          response => {
            this.photoList = response.data.records
            this.totalRecords = response.data.total
            console.log(this.photoList)
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    },
    checkUser(){
      let token = sessionStorage.getItem("myBlogUserToken");
      if (token == null) {
        token = localStorage.getItem("myBlogUserToken");
      }
      this.token = token
      if (token != null) {
        const hot = "/user/selectUserInfo";
        const config = {
          headers: {
            'Content-Type': 'application/json', // 设置请求头的 Content-Type
            'token': token
          }
        };
        api.get(hot,config).then(
            response => {
              if (response.state == 200){
                this.user = response.data
              } else if(response.state == 403){
                sessionStorage.clear();
                localStorage.clear();
                alert("登录过期")
              }
            }
        ).catch(
            error => {
              console.log(error)
            }
        )
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
      this.getPageList();
    },
    jumpToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    } ,
  }
}
</script>
  
  